<template>
  <div v-if="option">
    <div class="custom-check">
      <span v-bind:class="{'checked': selected != undefined}" @click="toggleOption"></span>
      <label class="ml-3" @click="toggleOption">{{ option.name }}</label>
    </div>
    <input type="hidden" :name="name" :value="selected" />
  </div>
</template>
 
<script>
  export default {
    props: ['paramOption', 'oldValue', 'name'],
    data() {
      return {
        option: undefined,
        selected: undefined
      }
    },
    created() {
      if (this.oldValue) this.selected = parseInt(this.oldValue)
      this.option = JSON.parse(this.paramOption)
    },
    methods: {
      toggleOption() {
        if (this.selected) {
          this.selected = undefined
        } else {
          this.selected = this.option.id
        }
      }
    }
  }
</script>
