<template>
  <div v-if="options" class="inline-block">
    <div class="custom-radio">
      <div v-for="(option, index) in options" class="radio-item" :key="index" v-bind:class="{'checked': option.value == selected}" @click="selected = option.value">
        {{ option.label }}
      </div>
    </div>
    <input type="hidden" :name="name" :value="selected" />
  </div>
</template>
 
<script>
  export default {
    props: ['paramOptions', 'oldValue', 'name'],
    data() {
      return {
        options: undefined,
        selected: undefined
      }
    },
    created() {
      if (this.oldValue) this.selected = parseInt(this.oldValue)
      this.options = JSON.parse(this.paramOptions)
    }
  }
</script>
