<template>
  <div class="row">
    <template v-if="selectedDate">
      <div class="col-md-2">
        <div class="font-24 text-bold mb-4">{{ selected_date_str }}</div>
        <button type="button" class="btn btn-sm btn-secondary btn-black w-100" @click="selectedDate = undefined">一覧に戻る</button>
        <a :href="day_download_url" class="btn btn-sm btn-info px-4 w-100 mt-2">ダウンロード</a>
      </div>
      <div class="col-md-10">
        <div class="table-responsive">
          <table class="table table-striped table-commits">
            <thead>
              <tr>
                <th style="min-width: 100px;">業種</th>
                <th style="min-width: 100px;">業者CD</th>
                <th style="min-width: 100px;">業者名</th>
                <th style="min-width: 60px;">物件コード</th>
                <th style="min-width: 60px;">物件名</th>
                <th style="min-width: 100px;">部屋番号</th>
                <th style="min-width: 100px;">メモ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(commit, ind) in selected_commits" :key="`commit_${ind}`">
                <td>{{ commit.trader.industry_label }}</td>
                <td>{{ commit.trader.code }}</td>
                <td>{{ commit.trader.name }}</td>
                <td>{{ commit.project.code }}</td>
                <td>{{ commit.project.name }}</td>
                <td>{{ commit.project.room_number }}号室</td>
                <td>{{ commit.content }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <div class="col-md-2" v-bind:class="{'visible_hidden': selectedDate != undefined}">
      <template v-if="selectedTraders.length > 0">
        <div class="mb-4 trader-list">
          <div v-for="(trader, ind) in selectedTraders" :key="`selected_trader_${trader.id}`" class="industry-item trader">
            <span class="checkbox" :style="`border-color: ${trader.color}; background-color: ${trader.color}`"></span>
            <span class="label">{{ trader.name }}</span>
          </div>
        </div>
      </template>
      <button type="button" class="btn btn-sm btn-secondary btn-black w-100" data-bs-toggle="modal" data-bs-target="#modal-select-trader" @click="startSelect">表示する業者を選択</button>
      <template v-if="selectedTraders.length > 0">
        <select class="form-control mt-4 mb-2" v-model="selectedTrader">
          <option v-for="(trader, ind) in selectedTraders" :key="`o_t_${trader.id}`" :value="trader.id">{{ trader.name }}</option>
        </select>
        <a v-if="selectedTrader" :href="download_url" target="_blank" style="display: none;" ref="btnDownload">ダウンロード</a>
        <a v-if="selectedTrader" href="javascript:void(0);" class="btn btn-sm btn-info px-4 w-100" data-bs-toggle="modal" data-bs-target="#modal-select-period" @click="formatPeriod">ダウンロード</a>
      </template>
    </div>
    <div class="col-md-10" v-bind:class="{'visible_hidden': selectedDate != undefined}">
      <FullCalendar ref="fullCalendar" :options="calendarOptions" />
    </div>

    <div class="modal fade" id="modal-select-period" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">ダウンロード期間選択</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="d-flex align-items-center">
              <input type="date" v-model="startAt" class="form-control" />
              <span class="mx-4">~</span>
              <input type="date" v-model="endAt" class="form-control" />
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-sm btn-info px-4" :disabled="!(startAt && endAt)" data-bs-dismiss="modal" @click="startDownload">ダウンロード</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modal-select-trader" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">表示する業者を選択</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-4">
                <div class="industry-item" @click="toggleAll">
                  <span class="checkbox" v-bind:class="{'checked': modalTraders.length == allTraders.length}"></span>
                  <span class="label">全てをチェック</span>
                </div>
                <div v-for="(industry, index) in rows[0]" :key="`industry_${index}`">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse_${industry.id}`" aria-expanded="false" aria-controls="collapseTwo">
                          <div class="industry-item">
                            <span class="checkbox" v-bind:class="{'checked': industry_check(industry.traders)}" @click="event => toggleIndustry(event, industry.traders)"></span>
                            <span class="label">{{ industry.name }}</span>
                          </div>
                        </button>
                      </h2>
                      <div :id="`collapse_${industry.id}`" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div v-for="(trader, ind) in industry.traders" :key="`trader_${trader.id}`" class="industry-item trader" @click="toggleTrader(trader)">
                            <span class="checkbox" :style="`border-color: ${trader.color}; ${trader_check_style(trader)}`"></span>
                            <span class="label">{{ trader.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div v-for="(industry, index) in rows[1]" :key="`industry_${index}`">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse_${industry.id}`" aria-expanded="false" aria-controls="collapseTwo">
                          <div class="industry-item">
                            <span class="checkbox" v-bind:class="{'checked': industry_check(industry.traders)}" @click="event => toggleIndustry(event, industry.traders)"></span>
                            <span class="label">{{ industry.name }}</span>
                          </div>
                        </button>
                      </h2>
                      <div :id="`collapse_${industry.id}`" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div v-for="(trader, ind) in industry.traders" :key="`trader_${trader.id}`" class="industry-item trader" @click="toggleTrader(trader)">
                            <span class="checkbox" :style="`border-color: ${trader.color}; ${trader_check_style(trader)}`"></span>
                            <span class="label">{{ trader.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div v-for="(industry, index) in rows[2]" :key="`industry_${index}`">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse_${industry.id}`" aria-expanded="false" aria-controls="collapseTwo">
                          <div class="industry-item">
                            <span class="checkbox" v-bind:class="{'checked': industry_check(industry.traders)}" @click="event => toggleIndustry(event, industry.traders)"></span>
                            <span class="label">{{ industry.name }}</span>
                          </div>
                        </button>
                      </h2>
                      <div :id="`collapse_${industry.id}`" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div v-for="(trader, ind) in industry.traders" :key="`trader_${trader.id}`" class="industry-item trader" @click="toggleTrader(trader)">
                            <span class="checkbox" :style="`border-color: ${trader.color}; ${trader_check_style(trader)}`"></span>
                            <span class="label">{{ trader.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-sm btn-info px-4" data-bs-dismiss="modal" @click="updateTraders">決定</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modal-more-commits" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body more-commits-body">
            <div class="row">
              <div class="col-sm-12">
                <div v-for="(commit, index) in moreCommits" :key="`industry_${index}`" class="commit-item" data-bs-dismiss="modal" @click="startEditCommit(commit)">
                  <span class="checkbox" :style="`background-color: ${commit.color}`"></span>
                  {{ commit.project.name }} {{ commit.project.room_number }}号室
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modal-commit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">{{ commit.project.name }} {{ commit.project.room_number }}号室（{{ commit.project.start_at_label }} ~ {{ commit.project.completed_at_label }}）</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-2">
              <label class="col-sm-3 color-gray font-12 mb-1">工程日付</label>
              <div class="col-sm-6">
                <input type="date" class="form-control" v-model="commit.date" @change="commit.start_at = commit.date; commit.end_at = commit.date;" />
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-3 color-gray font-12 mb-1">セクション</label>
              <div class="col-sm-6">
                <select v-model="commitMilestone" class="form-control">
                  <option value="">セクション</option>
                  <option v-for="(sec, ind) in sections" :key="`sec_${ind}`" :value="ind">{{ sec }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3 pb-3 border-bottom-1">
              <div class="col-sm-3 mb-2">
                <label class="color-gray font-12 mb-1">工程</label>
                <v-select v-model="commit.step_option" :options="industry_options" taggable placeholder="工程">
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      工程はありません。
                    </template>
                    <em v-else style="opacity: 0.5">検索中...</em>
                  </template>
                </v-select>
              </div>
              <div class="col-sm-3 mb-2">
                <label class="color-gray font-12 mb-1">業種</label>
                <v-select v-model="commit.industry_option" :options="industry_options" placeholder="業種">
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      業種はありません。
                    </template>
                    <em v-else style="opacity: 0.5">検索中...</em>
                  </template>
                </v-select>
              </div>
              <div class="col-sm-6 mb-2">
                <label class="color-gray font-12 mb-1">業者</label>
                <v-select v-model="commit.trader_option" :options="trader_options" placeholder="業者">
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      業者はございません。
                    </template>
                    <em v-else style="opacity: 0.5">検索中...</em>
                  </template>
                </v-select>
              </div>
              <div class="col-sm-3">
                <label class="color-gray font-12 mb-1">日程</label>
                <div class="d-flex align-items-center justify-content-between">
                  <input type="date" v-model="commit.start_at" class="form-control" />
                </div>
              </div>
              <div class="col-sm-3">
                <label class="color-gray font-12 mb-1">&nbsp;</label>
                <div class="d-flex align-items-center justify-content-between">
                  <input type="date" v-model="commit.end_at" class="form-control" />
                </div>
              </div>
              <div class="col-sm-3">
                <label class="color-gray font-12 mb-1">メモ</label>
                <div class="d-flex align-items-center justify-content-between">
                  <input type="text" v-model="commit.content" class="form-control" />
                </div>
              </div>
              <div class="col-sm-3 mb-2">
                <label class="color-gray font-12 mb-1">休み日設定</label>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center" style="height: 38px;">
                    <input type="checkbox" :id="`commit_rest_1`" :checked="commit.holidays.includes('sat')" @change="toggleCommitHoliday('sat')" />
                    <label :for="`commit_rest_1`" style="margin-left: 4px; margin-right: 8px;">土曜日</label>
                    <input type="checkbox" :id="`commit_rest_2`" :checked="commit.holidays.includes('holiday')" @change="toggleCommitHoliday( 'holiday')" />
                    <label :for="`commit_rest_2`" style="margin-left: 4px; margin-right: 8px;">祝日</label>
                    <input type="checkbox" :id="`commit_rest_3`" :checked="commit.holidays.includes('sun')" @change="toggleCommitHoliday('sun')" />
                    <label :for="`commit_rest_3`" style="margin-left: 4px;">日曜日</label>
                  </div>
                  <button type="button" class="btn-close ml-2" @click="event => removeCommit(event, ind)"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary mr-2 px-4" data-bs-dismiss="modal" id="btnUpdateCommitClose">キャンセル</button>
            <button type="button" class="btn btn-sm btn-info px-4" @click="uploadCommits">コピー</button>
            <button type="button" class="btn btn-sm btn-info px-4" @click="updateCommit">保存</button>
            <button type="button" class="btn btn-sm btn-danger px-4" @click="deleteCommit">削除</button>
          </div>
        </div>
      </div>
    </div>

    <span class="hidden" data-bs-toggle="modal" data-bs-target="#modal-more-commits" ref="openMoreCommits"></span>
    <span class="hidden" data-bs-toggle="modal" data-bs-target="#modal-commit" ref="commitCopyModal"></span>
    <loading :active.sync="isLoading"
        :can-cancel="false"
        color="#1D1F32"
        :width="36"
        :height="36"
        :is-full-page="true"></loading>
  </div>
</template>
 
<script>
  import '@fullcalendar/core/vdom' // solves problem with Vite
  import FullCalendar from '@fullcalendar/vue3'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import ja from '@fullcalendar/core/locales/ja';
  import moment from 'moment';

  export default {
    props: [
      'paramIndustries',
      'paramTrader',
      'commitUrl',
      'csrf',
      'latestMonthUrl',
      'updateUrl',
      'paramTraders',
      'paramSections',
      'paramSteps',
      'paramHolidays'
    ],
    components: {
      FullCalendar // make the <FullCalendar> tag available
    },
    data() {
      return {
        calendarOptions: {
          plugins: [ dayGridPlugin, interactionPlugin ],
          initialView: 'dayGridMonth',
          dateClick: this.handleDateClick,
          datesSet: this.handleMonthChange,
          eventClick: this.handleEventClick,
          events: [],
          locale: ja,
          headerToolbar: {
            start: 'prev',
            center: 'title',
            end: 'next'
          },
          eventDisplay: 'list-item',
          eventTextColor: "#000",
          firstDay: 1,
          eventDidMount: function(info) {
            var tooltip = new Tooltip(info.el, {
              title: info.event.extendedProps.description,
              placement: 'top',
              trigger: 'hover',
              container: 'body'
            });
          },
          eventOrder: 'order'
        },
        industries: [],
        trader: undefined,
        selectedTraders: [],
        rows: [
          [],
          [],
          []
        ],
        modalTraders: [],
        allTraders: [],
        selectedTrader: undefined,
        isLoading: false,
        commits: [],
        moreCommits: [],
        selectedDate: undefined,
        commitMilestone: undefined,
        commit: {
          step_option: {
            code: '',
            label: ''
          },
          trader_option: {
            code: '',
            label: '業者'
          },
          industry_option: {
            code: '',
            label: '業種'
          },
          content: '',
          id: -1,
          project: {},
          start_at: undefined,
          end_at: undefined,
          holidays: ['sun']
        },
        sections: [],
        traders: [],
        startAt: moment().format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
        holidays: []
      }
    },
    created() {
      this.traders = JSON.parse(this.paramTraders)
      this.sections = JSON.parse(this.paramSections)
      this.industries = JSON.parse(this.paramIndustries);
      this.holidays = JSON.parse(this.paramHolidays);
      let temp = JSON.parse(this.paramIndustries)
      if (this.paramTrader && this.paramTrader != 'null') this.trader = JSON.parse(this.paramTrader)

      if (temp.length > 0) {
        let end = Math.ceil(temp.length / 3);
        this.rows[0] = temp.filter((item, index) => index <= end)
        temp = temp.filter((item, index) => index > end)
        end = Math.ceil(temp.length / 2);
        this.rows[1] = temp.filter((item, index) => index <= end)
        this.rows[2] = temp.filter((item, index) => index > end)
      }
      this.industries.map(item => {
        item.traders.map(trader => {
          if (this.allTraders.filter(aT => aT.id == trader.id).length == 0) this.allTraders.push(trader)
        })
      })
    },
    mounted() {
      if (this.trader) {
        this.selectedTraders.push(this.trader)
        let self = this
        this.$nextTick(function() {
          self.firstLoading()
        })
      } else {
        if (window.localStorage.getItem('selected_traders')) {
          this.selectedTraders = JSON.parse(window.localStorage.getItem('selected_traders'))
          this.$refs.fullCalendar.getApi().gotoDate(moment(window.localStorage.getItem('selected_month')).format('YYYY-MM-DD'))
          this.refreshCalendar()
        }
      }
    },
    methods: {
      handleDateClick(arg) {
        this.selectedDate = arg.dateStr
      },
      handleMonthChange(arg) {
        if (this.isLoading == false) this.refreshCalendar()
      },
      handleEventClick(arg) {
        if (arg.event.id == '-1') {
          let date = arg.event.startStr;
          let weekday = moment(date).format('d')
          const is_holiday = this.isHoliday(date)
          this.moreCommits = this.commits.filter(commit => {
                  if (commit.holidays.includes('sat') && weekday == 6) return false
                  if (commit.holidays.includes('sun') && weekday == 0) return false
                  if (commit.holidays.includes('holiday') && is_holiday) return false

                  if (commit.end_at) {
                    return date >= moment(commit.start_at).format('YYYY-MM-DD') && date <= moment(commit.end_at).format('YYYY-MM-DD');
                  }    

                  return date >= moment(commit.start_at).format('YYYY-MM-DD');
                })
          this.$refs.openMoreCommits.click();
        } else {
          this.editCommit(arg.event.id)
        }
      },
      startEditCommit(commit) {
        this.editCommit(commit.id)
      },
      editCommit(id) {
        let temp = this.commits.filter(item => item.id == id)
        if (temp.length > 0) {
          const commit = temp[0]
          const dateLabel = moment(commit.date).format('YYYY-MM-DD')
          this.commit = {
            step_option: {
              code: commit.step,
              label: commit.step
            },
            trader_option: {
              code: commit.trader.id,
              label: commit.trader.name
            },
            industry_option: {
              code: commit.industry ? commit.industry.id : '',
              label: commit.industry ? commit.industry.name : '業種'
            },
            content: commit.content,
            id: commit.id,
            date: moment(commit.date).format('YYYY-MM-DD'),
            start_at: commit.start_at ? moment(commit.start_at).format('YYYY-MM-DD') : undefined,
            end_at: commit.end_at ? moment(commit.end_at).format('YYYY-MM-DD') : undefined,
            project: {
              name: commit.project.name,
              start_at_label: commit.project.start_at_label,
              completed_at_label: commit.project.completed_at_label,
              id: commit.project.id,
              room_number: commit.project.room_number
            },
            color: `${this.selectedTraders.filter(trader => trader.id == commit.trader_id)[0].color}`,
            holidays: commit.holidays
          }
          if (commit.project.milestones.filter(item => item.date_label == dateLabel).length > 0) {
            const milestone = commit.project.milestones.filter(item => item.date_label == dateLabel)[0]
            this.commitMilestone = milestone.section
          } else {
            this.commitMilestone = undefined
          }
          this.$refs.commitCopyModal.click();
        }
      },
      toggleTrader(trader) {
        if (this.modalTraders.filter(item => item.id == trader.id).length > 0) {
          this.modalTraders = this.modalTraders.filter(item => item.id != trader.id)
        } else {
          this.modalTraders.push(trader)
        }
      },
      toggleAll() {
        if (this.modalTraders.length == this.allTraders.length) {
          this.modalTraders = []
        } else {
          this.modalTraders = JSON.parse(JSON.stringify(this.allTraders))
        }
      },
      startSelect() {
        this.modalTraders = JSON.parse(JSON.stringify(this.selectedTraders))
      },
      hasTrader(trader) {
        return this.modalTraders.filter(item => item.id == trader.id).length > 0
      },
      trader_check_style(trader) {
        if (this.hasTrader(trader)) return `background-color: ${trader.color}`;

        return '';
      },
      industry_check(traders) {
        if (traders.length == 0) return false;
        let ids = traders.map(item => item.id)
        return this.modalTraders.filter(item => ids.includes(item.id)).length == ids.length
      },
      toggleIndustry(event, traders) {
        if (traders.length == 0) return;

        event.preventDefault();
        event.stopPropagation();

        let ids = traders.map(item => item.id)
        if (this.modalTraders.filter(item => ids.includes(item.id)).length == ids.length) {
          this.modalTraders = this.modalTraders.filter(item => !ids.includes(item.id))
        } else {
          traders.map(trader => {
            if (this.modalTraders.filter(item => item.id == trader.id).length == 0) this.modalTraders.push(trader)
          })
        }
      },
      async firstLoading() {
        let res = await axios.post(this.latestMonthUrl, {
          _token: this.csrf,
          ids: this.selectedTraders.map(item => item.id).join(',')
        })
        this.$refs.fullCalendar.getApi().gotoDate(res.data.latest_date)

        this.selectedTrader = this.selectedTraders[0].id
        this.refreshCalendar()
      },
      async updateTraders() {
        this.selectedTraders = JSON.parse(JSON.stringify(this.modalTraders))
        this.selectedTrader = undefined
        if (this.selectedTraders.length > 0) {
          let res = await axios.post(this.latestMonthUrl, {
            _token: this.csrf,
            ids: this.selectedTraders.map(item => item.id).join(',')
          })
          this.$refs.fullCalendar.getApi().gotoDate(res.data.latest_date)

          this.selectedTrader = this.selectedTraders[0].id
          this.refreshCalendar()
        } else {
          this.$refs.fullCalendar.getApi().gotoDate(moment().format('YYYY-MM-DD'))
          this.calendarOptions.events = []
          this.$forceUpdate()
        }
      },
      refreshCalendar() {
        if (this.selectedTraders.length == 0) return;
        if (this.isLoading) return;

        let self = this
        this.isLoading = true
        let temp = this.$refs.fullCalendar.calendar.currentData.viewTitle.replace('年', '-').replace('月', '').split('-')
        let month = temp[0] + "-" + (("0" + temp[1]).slice(-2)) + "-01"

        window.localStorage.setItem('selected_traders', JSON.stringify(this.selectedTraders))
        window.localStorage.setItem('selected_month', month)

        month = moment(month).format('YYYY-MM')
        axios.post(this.commitUrl, {
          _token: this.csrf,
          ids: this.selectedTraders.map(item => item.id).join(','),
          month: month
        }).then(response => {
          let commits = response.data.commits
          let dates = response.data.dates.map(date => moment(date).format('YYYY-MM-DD'))
          let events = []
          dates.map(date => {
            let weekday = moment(date).format('d')
            const is_holiday = this.isHoliday(date)
            const tempCommits = commits.filter(commit => {
                  if (commit.holidays.includes('sat') && weekday == 6) return false
                  if (commit.holidays.includes('sun') && weekday == 0) return false
                  if (commit.holidays.includes('holiday') && is_holiday) return false

                  if (commit.end_at) {
                    return date >= moment(commit.start_at).format('YYYY-MM-DD') && date <= moment(commit.end_at).format('YYYY-MM-DD');
                  }    

                  return date >= moment(commit.start_at).format('YYYY-MM-DD');
                })
            let commitLen = tempCommits.length
            tempCommits.filter((item, index) => index < 3)
                .map((commit, index) => {
                  events.push({ 
                    title: `${commit.project.name} ${commit.project.room_number}号室`, 
                    date: date, 
                    color: `${this.selectedTraders.filter(trader => trader.id == commit.trader_id)[0].color}`, 
                    description: commit.content,
                    order: index,
                    id: commit.id
                  })
                })

            if (commitLen > 3) {
              events.push({
                title: '他' + (commitLen - 3) + '件',
                date: date,
                color: '#23B5DB',
                display: 'auto',
                textColor: '#fff',
                order: 5,
                id: -1
              })
            }
          })
          this.commits = response.data.commits.map(commit => {
            commit.color = `${this.selectedTraders.filter(trader => trader.id == commit.trader_id)[0].color}`
            commit.trader = this.selectedTraders.filter(trader => trader.id == commit.trader_id)[0]
            return commit
          })
          this.calendarOptions.events = events
          this.$forceUpdate()
        }).finally(() => {
          setTimeout(function() {
            self.isLoading = false
          }, 500)
        })
      },
      async update(project_id, action, params) {
        return await axios.post(this.updateUrl, Object.assign({
          _token: this.csrf,
          action: action,
          project_id: project_id
        }, params))
      },
      isHoliday(value) {
        return this.holidays.filter(item => value >= item.start_label && value <= item.end_label).length > 0;
      },
      async uploadCommits() {
        if (this.commit.date && this.commit.date != '' && this.commit.start_at && this.commit.start_at != '' && this.commit.end_at && this.commit.end_at != '' && this.commit.step_option && this.commit.step_option.label != '') {
          this.isLoading = true
          const response = await this.update(this.commit.project.id, 'copy_commits', {
            commit: {
              step: this.commit.step_option ? this.commit.step_option.label : '',
              content: this.commit.content,
              trader_id: this.commit.trader_option ? this.commit.trader_option.code : '',
              industry_id: this.commit.industry_option ? this.commit.industry_option.code : '',
              start_at: this.commit.start_at,
              end_at: this.commit.end_at,
              holidays: this.commit.holidays.filter(item => item != '').join(',')
            },
            date: this.commit.date,
            milestone: this.commitMilestone
          })

          this.isLoading = false
          $('#btnUpdateCommitClose').click();

          this.refreshCalendar()
        }
      },
      async updateCommit() {
        if (this.commit.date && this.commit.date != '' && this.commit.step_option && this.commit.step_option.label != '') {
          this.isLoading = true
          const response = await this.update(this.commit.project.id, 'update_commit', {
            commit: {
              step: this.commit.step_option ? this.commit.step_option.label : '',
              content: this.commit.content,
              trader_id: this.commit.trader_option ? this.commit.trader_option.code : '',
              industry_id: this.commit.industry_option ? this.commit.industry_option.code : '',
              start_at: this.commit.start_at,
              end_at: this.commit.end_at,
              holidays: this.commit.holidays.filter(item => item != '').join(',')
            },
            date: this.commit.date,
            milestone: this.commitMilestone,
            commit_id: this.commit.id
          })

          this.isLoading = false
          $('#btnUpdateCommitClose').click();

          this.refreshCalendar()
        }
      },
      async deleteCommit() {
        this.isLoading = true
        const response = await this.update(this.commit.project.id, 'delete_commit', {
          commit_id: this.commit.id,
          date: this.commit.date,
        })

        this.isLoading = false
        $('#btnUpdateCommitClose').click();

        this.refreshCalendar()
      },
      formatPeriod() {
        this.startAt = moment().add(-2, 'month').format('YYYY-MM-DD')
        this.endAt = moment().format('YYYY-MM-DD')
      },
      startDownload() {
        this.$refs.btnDownload.click();
      },
      toggleCommitHoliday(value) {
        if (this.commit.holidays.includes(value))
          this.commit.holidays = this.commit.holidays.filter(item => item != value)
        else
          this.commit.holidays.push(value)
      }
    },
    computed: {
      selected_date_str() {
        if (this.selectedDate) return moment(this.selectedDate).format('MM月DD日')

        return ''
      },
      selected_commits() {
        if (this.selectedDate) {
          let weekday = moment(this.selectedDate).format('d')
          const is_holiday = this.isHoliday(this.selectedDate)

          return this.commits.filter(commit => {
            if (commit.holidays.includes('sat') && weekday == 6) return false
            if (commit.holidays.includes('sun') && weekday == 0) return false
            if (commit.holidays.includes('holiday') && is_holiday) return false

            if (commit.end_at) {
              return this.selectedDate >= moment(commit.start_at).format('YYYY-MM-DD') && this.selectedDate <= moment(commit.end_at).format('YYYY-MM-DD')
            }

            return this.selectedDate >= moment(commit.start_at).format('YYYY-MM-DD');
          }).sort((a, b) => {
            if (a.trader.industry_label > b.trader.industry_label) return 1;
            if (a.trader.industry_label < b.trader.industry_label) return -1;
            return 0;
          })
        }

        return []
      },
      trader_options() {
        if (this.traders) return this.traders.map(item => {
          return {
            code: item.id,
            label: item.name
          }
        })

        return []
      },
      industry_options() {
        if (this.industries) return this.industries.map(item => {
          return {
            code: item.id,
            label: item.name
          }
        })

        return []
      },
      steps() {
        const temp = JSON.parse(this.paramSteps)

        return temp.map(item => {
          return {
            code: item,
            label: item
          }
        })
      },
      download_url() {
        if (!this.selectedTrader) return '';
        if (!this.startAt) return '';
        if (!this.endAt) return '';

        return `/calendars/${this.selectedTrader}/export?from=${this.startAt}&to=${this.endAt}`;
      },
      day_download_url() {
        if (!this.selected_commits || this.selected_commits.length == 0) return '';
        if (!this.selectedDate) return '';

        const ids = this.selected_commits.map(item => item.id).join(',');
        return `/calendars/commits_export?date=${this.selectedDate}&ids=${ids}`
      }
    }
  }
</script>

<style lang="scss" scoped>
.industry-item {
  position: relative;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 4px 4px 22px;
  cursor: pointer;
  border-bottom: 1px solid #eee;

  .checkbox {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 9px;
    left: 6px;
    border: 1px solid #000;

    &.checked {
      background: #000;
    }
  }

  &.trader {
    padding: 6px 6px 6px 30px;

    .checkbox {
      left: 10px;
      width: 12px;
      height: 12px;
    }
  }
}

.accordion-button {
  padding: 0;
  border-radius: 0 !important;

  &:after {
    background-size: 12px;
    width: 12px;
    height: 12px;
  }

  .industry-item {
    border: none;
  }
}

.accordion-item {
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
}
.accordion-body {
  padding: 0px;
}

.trader-list {
  max-height: 50vh;
  overflow-y: auto;
}

.commit-item {
  position: relative;
  font-size: 13px;
  line-height: 22px;
  height: auto;
  padding: 0 0 6px 20px;
  color: #0d6efd;
  font-weight: bold;
  cursor: pointer;

  .checkbox {
    width: 8px;
    height: 8px;
    position: absolute;
    top: 7px;
    left: 4px;
    border-radius: 4px;
  }
}

.more-commits-body {
  max-height: 60vh;
  overflow-y: auto;
}

.visible_hidden {
  display: none;
}
.font-24 {
  font-size: 24px;
}
.table-commits {
  font-size: 13px;
}
</style>
