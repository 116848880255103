<template>
  <div></div>
</template>
 
<script>
  export default {
    props: ['message', 'type', 'position'],
    data() {
      return {
          
      }
    },
    mounted() {
      centerToastr.fire({
        icon: this.type,
        text: this.message,
      })
    }
  }
</script>
