<template>
  <div>
    <div v-if="projects">
      <div class="mb-3 d-flex justify-content-end">
        <button type="button" class="btn btn-sm btn-info px-4 mr-3" @click="toggleAll(false)">全て閉じる</button>
        <button type="button" class="btn btn-sm btn-info px-4" @click="toggleAll(true); scrollToToday()">全て展開</button>
      </div>
      <div>
        <table class="table table-striped">
          <template v-for="(project, index) in projects" :key="`info_${index}`">
            <tr class="project-info">
              <td style="padding: 0 8px; width: 136px;">
                <select v-model="project.section" class="form-control" @change="updateSection(project)">
                  <option v-for="(sec, ind) in sections" :key="`sec_${ind}`" :value="ind">{{ sec }}</option>
                </select>
              </td>
              <td style="max-width: 80px; width: 80px;">
                <button type="button" class="btn btn-sm btn-open" @click="project.isOpen = !project.isOpen;scrollToToday()">{{ project.isOpen ? '閉じる' : '展開' }}</button>
              </td>
              <td>
                {{ project.code }}_{{ project.name }} {{ project.room_number ? `${project.room_number}号室` : '' }}
              </td>
              <td>
                <i className="label">取引先</i><span className="text">{{ project.client_name }}</span>
              </td>
              <td class="py-3">
                <div class="mb-2 p-0">
                  <i className="label">統括管理部門</i><span className="text">{{ project.supervisor }}</span>
                </div>
                <div class="p-0">
                  <i className="label">営業</i><span className="text">{{ project.manager }}</span>
                </div>
              </td>
              <td class="py-3">
                <div class="mb-2 p-0">
                  <i className="label">着工</i><span className="text">{{ project.start_at_label }}</span>
                </div>
                <div class="p-0">
                  <i className="label">完工</i><span className="text">{{ project.completed_at_label }}</span>
                </div>
              </td>
              <td class="d-flex justify-content-end align-items-center" style="height: 90px;">
                <div class="d-flex align-items-center">
                  <a v-if="!isGuest" v-bind:class="{'disabled': !project.integration_link || project.integration_link == ''}" :href="project.integration_link ? project.integration_link : 'javascript:void(0);'" :target="project.integration_link ? '_blank' : ''">
                    <img src="/imgs/integration.svg" width="38" height="38" alt="積算表"/>
                  </a>
                  <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modal-update" @click="editProject(project)">
                    <img src="/imgs/detail.png" width="38" height="38" alt="詳細"/>
                  </a>
                  <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modal-documents" @click="editDocument(project)">
                    <img src="/imgs/document.png" width="38" height="38" alt=""/>
                  </a>
                  <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modal-photos" @click="editPhoto(project)">
                    <img src="/imgs/photo.png" width="38" height="38" alt=""/>
                  </a>
                  <a :href="`/projects/${project.id}/export`" className="proj-action proj-download-btn" target="_blank">
                    <img src="/imgs/download2.png" width="38" height="38" alt=""/>
                  </a>
                  <a v-if="!isGuest" href="javascript:void(0);" class="btn-history" data-bs-toggle="modal" :data-bs-target="`#history-modal-${project.id}`">変更<br/>履歴</a>
                </div>
              </td>
            </tr>
            <tr v-bind:class="{'hidden': !project.isOpen}">
              <td colspan="7">
                <div class="timeline" :id="`timeline_${project.id}`">
                  <table>
                    <tr>
                      <td></td>
                      <td v-for="(timeline, ind) in project.timelines" :key="`timeline_th_${ind}`" :class="`timeline-header ${timeline.customClass}`" v-bind:class="{'checked': timeline.isChecked}" @click="checkDate(project, timeline)" :id="`td_${project.id}_${timeline.day}`">
                        <span>{{ timeline.label }}</span>
                        <i class="timeline-date-checked"></i>
                      </td>
                    </tr>
                    <tr>
                      <td class="timeline-label">工程</td>
                      <td v-for="(timeline, ind) in project.timelines" :key="`timeline_th_${ind}`" class="timeline-step">
                        <div class="step-info">
                          <ul class="step-items">
                            <li v-for="(commit, cInd) in timeline.commits" :key="`commit_${cInd}`" v-bind:class="{'overlapped': commit.overlapped}">
                              {{ commit.step }}
                              <a v-if="commit.trader" :href="`/calendars?trader=${commit.trader.id}`">({{ commit.trader.name }})</a>
                              <span v-if="commit.content && commit.content != ''">[{{ commit.content }}]</span>
                            </li>
                          </ul>
                          <div v-if="timeline.milestone != ''" class="milestone">
                            {{ sections[timeline.milestone] ?? '' }}
                          </div>
                        </div>
                        <i class="chart-view-edit" @click="editCommit(timeline)" data-bs-toggle="modal" data-bs-target="#modal-commit"></i>
                      </td>
                    </tr>
                    <tr>
                      <td class="timeline-label">納品物</td>
                      <td v-for="(timeline, ind) in project.timelines" :key="`timeline_th_${ind}`" class="timeline-goal">
                        <div v-if="currentTimeline && timeline.day == currentTimeline.day && isGoalEditing">
                          <textarea class="form-control" v-model="goalEdit"></textarea>
                          <div class="d-flex align-items-center justify-content-end" style="margin-top: 4px;">
                            <button type="button" class="btn btn-sm btn-secondary" style="font-size: 12px; margin-right: 4px;" @click="cancelGoalEdit">キャンセル</button>
                            <button type="button" class="btn btn-sm btn-info" style="font-size: 12px;" @click="updateGoal">保存</button>
                          </div>
                        </div>
                        <template v-else>
                          <div v-html="timeline.goal"></div>
                          <i class="chart-view-edit" @click="editGoal(timeline)"></i>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="timeline-label">連絡</td>
                      <td v-for="(timeline, ind) in project.timelines" :key="`timeline_th_${ind}`" class="timeline-message">
                        <ul class="message-items">
                          <li v-for="(note, nInd) in timeline.notes" :key="`note_${nInd}`" @click="editNote(timeline, note)" data-bs-toggle="modal" data-bs-target="#modal-note">
                            <img v-if="note.staff" :src="note.staff.image_url" />
                            <img v-else src="/imgs/staff-base/0.png" />
                            <span :class="`note-${note.level} pre-wrap`" v-html="note.content"></span>
                          </li>
                        </ul>
                        <i class="chart-view-edit" @click="addNote(timeline)" data-bs-toggle="modal" data-bs-target="#modal-note"></i>
                      </td>
                    </tr>
                  </table>
                </div>
                <div v-if="project.note && project.note != ''" style="color: #f00; font-size: 11px;" class="d-flex align-items-center">
                  <img src="/imgs/project-note-icon.svg" />
                  <div style="margin-left: 4px;">{{ project.note }}</div>
                </div>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <div class="modal fade" id="modal-goal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title" v-if="currentTimeline">納品物({{ currentTimeline.label }})</h6>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <textarea v-model="goalEdit" rows="3" class="form-control" style="resize: none;"></textarea>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-secondary mr-3 px-4" data-bs-dismiss="modal">キャンセル</button>
              <button type="button" class="btn btn-sm btn-info px-4" @click="updateGoal" data-bs-dismiss="modal">保存</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="modal-note" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title" v-if="currentTimeline">連絡({{ currentTimeline.label }})</h6>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <label class="color-gray font-12 mb-1">宛先</label>
              <select class="form-control mb-2" v-model="noteEdit.staff">
                <option value="">全員</option>
                <option v-for="(s, i) in staffs" :key="`sta_${i}`" :value="s.id">{{ s.name }}</option>
              </select>
              <label class="color-gray font-12 mb-1">重要度</label>
              <select class="form-control mb-2" v-model="noteEdit.level">
                <option value="normal">通常</option>
                <option value="caution">注意</option>
                <option value="important">重要</option>
              </select>
              <label class="color-gray font-12 mb-1">内容</label>
              <textarea v-model="noteEdit.content" rows="3" class="form-control" style="resize: none;"></textarea>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-secondary mr-2 px-2" data-bs-dismiss="modal">キャンセル</button>
              <button v-if="noteEdit.id && noteEdit.id > 0" type="button" class="btn btn-sm btn-danger mr-2 px-2" @click="deleteNote" data-bs-dismiss="modal">削除</button>
              <button type="button" class="btn btn-sm btn-info px-2" @click="updateNote" data-bs-dismiss="modal">保存</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="modal-commit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title" v-if="currentTimeline">工程({{ currentTimeline.label }})</h6>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row mb-2">
                <label class="col-sm-3 color-gray font-12 mb-1">セクション</label>
                <div class="col-sm-6">
                  <select v-model="commitMilestone" class="form-control">
                    <option value="">セクション</option>
                    <option v-for="(sec, ind) in sections" :key="`sec_${ind}`" :value="ind">{{ sec }}</option>
                  </select>
                </div>
              </div>
              <div v-for="(commit, ind) in commitsEdit" :key="`commit_${ind}`" class="row mb-3 pb-3 border-bottom-1">
                <div class="col-sm-3 mb-2">
                  <label class="color-gray font-12 mb-1">工程</label>
                  <v-select v-model="commit.step_option" :options="steps" taggable placeholder="工程">
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        工程はありません。
                      </template>
                      <em v-else style="opacity: 0.5">検索中...</em>
                    </template>
                  </v-select>
                </div>
                <div class="col-sm-3 mb-2">
                  <label class="color-gray font-12 mb-1">業種</label>
                  <v-select v-model="commit.industry_option" :options="industry_options" placeholder="業種">
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        業種はありません。
                      </template>
                      <em v-else style="opacity: 0.5">検索中...</em>
                    </template>
                  </v-select>
                </div>
                <div class="col-sm-6 mb-2">
                  <label class="color-gray font-12 mb-1">業者</label>
                  <v-select v-model="commit.trader_option" :options="trader_options" placeholder="業者">
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        業者はございません。
                      </template>
                      <em v-else style="opacity: 0.5">検索中...</em>
                    </template>
                  </v-select>
                </div>
                <div class="col-sm-3">
                  <label class="color-gray font-12 mb-1">日程</label>
                  <div class="d-flex align-items-center justify-content-between">
                    <input type="date" v-model="commit.start_at" class="form-control" />
                  </div>
                </div>
                <div class="col-sm-3">
                  <label class="color-gray font-12 mb-1">&nbsp;</label>
                  <div class="d-flex align-items-center justify-content-between">
                    <input type="date" v-model="commit.end_at" class="form-control" />
                  </div>
                </div>
                <div class="col-sm-3">
                  <label class="color-gray font-12 mb-1">メモ</label>
                  <div class="d-flex align-items-center justify-content-between">
                    <input type="text" v-model="commit.content" class="form-control" />
                  </div>
                </div>
                <div class="col-sm-3 mb-2">
                  <label class="color-gray font-12 mb-1">休み日設定</label>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center" style="height: 38px;">
                      <input type="checkbox" :id="`commit_rest_${ind}_1`" :checked="commit.holidays.includes('sat')" @change="toggleCommitHoliday(ind, 'sat')" />
                      <label :for="`commit_rest_${ind}_1`" style="margin-left: 4px; margin-right: 8px;">土曜日</label>
                      <input type="checkbox" :id="`commit_rest_${ind}_2`" :checked="commit.holidays.includes('holiday')" @change="toggleCommitHoliday(ind, 'holiday')" />
                      <label :for="`commit_rest_${ind}_2`" style="margin-left: 4px; margin-right: 8px;">祝日</label>
                      <input type="checkbox" :id="`commit_rest_${ind}_3`" :checked="commit.holidays.includes('sun')" @change="toggleCommitHoliday(ind, 'sun')" />
                      <label :for="`commit_rest_${ind}_3`" style="margin-left: 4px;">日曜日</label>
                    </div>
                    <button type="button" class="btn-close ml-2" @click="event => removeCommit(event, ind)"></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 d-flex align-items-center justify-content-end">
                  <button type="button" class="btn btn-sm btn-danger px-4" @click="addCommit">追加</button>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-secondary mr-3 px-4" data-bs-dismiss="modal" id="btnUpdateCommitClose">キャンセル</button>
              <button type="button" class="btn btn-sm btn-info px-4" @click="updateCommits">保存</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="modal-update" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title">案件を編集</h6>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row mb-2">
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">営業</label>
                <div class="col-sm-4">
                  <v-select :options="staff_options" placeholder="営業" v-model="projectEdit.manager">
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        営業はございません。
                      </template>
                      <em v-else style="opacity: 0.5">検索中...</em>
                    </template>
                  </v-select>
                </div>
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">物件コード<span class="ml-2 color-red">*</span></label>
                <div class="col-sm-4">
                  <input type="text" class="form-control" v-model="projectEdit.code" v-bind:class="{'is-invalid': projectEdit.code == ''}" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">お取引先</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model="projectEdit.client_name" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">現場名<span class="ml-2 color-red">*</span></label>
                <div class="col-sm-10 d-flex align-items-center justify-content-center">
                  <input type="text" class="form-control mr-3" v-model="projectEdit.name" v-bind:class="{'is-invalid': projectEdit.name == ''}" />
                  <input type="text" class="form-control mr-3" v-model="projectEdit.room_number" />
                  <span style="width: 100px;">号室</span>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">現場住所</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model="projectEdit.address" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">着工<span class="ml-2 color-red">*</span></label>
                <div class="col-sm-4">
                  <datepicker v-model="projectEdit.start_at" :locale="ja" class="form-control" v-bind:class="{'is-invalid': projectEdit.start_at == undefined}" :clearable="true" />
                </div>
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">完工<span class="ml-2 color-red">*</span></label>
                <div class="col-sm-4">
                  <datepicker v-model="projectEdit.completed_at" :locale="ja" class="form-control" v-bind:class="{'is-invalid': projectEdit.completed_at == undefined}" :clearable="true" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">土曜作業</label>
                <div class="col-sm-4">
                  <div class="inline-block">
                    <div class="custom-radio">
                      <div class="radio-item" v-bind:class="{'checked': projectEdit.saturday_work == 1}" @click="projectEdit.saturday_work = 1">
                        可能
                      </div>
                      <div class="radio-item" v-bind:class="{'checked': projectEdit.saturday_work == 0}" @click="projectEdit.saturday_work = 0">
                        不可
                      </div>
                    </div>
                  </div>
                </div>
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">土曜音出し</label>
                <div class="col-sm-4">
                  <div class="inline-block">
                    <div class="custom-radio">
                      <div class="radio-item" v-bind:class="{'checked': projectEdit.saturday_noise == 1}" @click="projectEdit.saturday_noise = 1">
                        可能
                      </div>
                      <div class="radio-item" v-bind:class="{'checked': projectEdit.saturday_noise == 0}" @click="projectEdit.saturday_noise = 0">
                        禁止
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">統括管理部門</label>
                <div class="col-sm-4">
                  <v-select :options="office_options" placeholder="-" v-model="projectEdit.supervisor">
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        統括管理部門はございません。
                      </template>
                      <em v-else style="opacity: 0.5">検索中...</em>
                    </template>
                  </v-select>
                </div>
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">工事担当</label>
                <div class="col-sm-4">
                  <v-select :options="staff_options" placeholder="-" v-model="projectEdit.construction_manager">
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        工事担当はございません。
                      </template>
                      <em v-else style="opacity: 0.5">検索中...</em>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">鍵の場所</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model="projectEdit.key_place" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">備考</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model="projectEdit.note" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">積算表リンク</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model="projectEdit.integration_link" />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-secondary mr-3 px-4" data-bs-dismiss="modal" id="cancelProjectEditModal">キャンセル</button>
              <button type="button" class="btn btn-sm btn-info mr-3 px-4" :disabled="!can_save" data-bs-dismiss="modal" @click="copyProject">コピー</button>
              <button type="button" class="btn btn-sm btn-info mr-3 px-4" :disabled="!can_save" data-bs-dismiss="modal" @click="updateProject">保存</button>
              <button type="button" class="btn btn-sm btn-danger px-4" @click="deleteProject">削除</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="modal-photos" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="photo-modal-header">
                <img src="/imgs/photo.png" />
                <span>PHOTO</span>
              </div>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" v-if="photoEdit">
              <div class="text-center">
                <div class="mb-3">{{ photoEdit.project.name }}</div>
                <div class="category-selector">
                  <select class="form-control" v-model="photoEdit.album">
                    <option value="">全て</option>
                    <option v-for="(value, index) in albums" :key="`album_${index}`" :value="value">{{ value }}</option>
                  </select>
                </div>
                <file-selector
                  accept-extensions=".jpg,.jpeg,.png"
                  :multiple="false"
                  :max-file-size="15 * 1024 * 1024"
                  @validated="handleFilesValidated"
                  @changed="changeImage"
                  input-name="file"
                  >
                  <slot name="top">
                    <div class="tutor-thumb-image border-1" v-if="album_image !== '/imgs/no-image.jpg'">
                      <img :src="album_image" />
                      <i class="fas fa-times-circle" @click="event => removeImage(event)"></i>
                    </div>
                    <p class="mt-3 mb-3" v-else>
                      <img src="/imgs/icon_upload.svg" alt="" />
                    </p>
                  </slot>
                  <slot name="default">
                    <p class="fs-sm text-center mb-2" style="color: #f00;" v-bind:class="{'hidden': album_image !== '/imgs/no-image.jpg'}">※画像サイズ15MBまで</p>
                    <button type="button" class="btn btn-sm btn-default" v-bind:class="{'hidden': album_image !== '/imgs/no-image.jpg'}">画像を追加する</button>
                  </slot>
                  <slot name="bottom">
                    <p class="mt-3 mb-3" v-bind:class="{'hidden': album_image !== '/imgs/no-image.jpg'}">または、画像をドロップしてアップロード</p>
                  </slot>
                </file-selector>
                <template v-if="photoEdit.project.pictures.length > 0">
                  <hr/>
                  <div class="project-pictures">
                    <div v-for="(picture, ind) in photoEdit.project.pictures" :key="`picture_${ind}`" class="picture-item" @click="fullImage = picture.file_url">
                      <img :src="picture.file_url" />
                      <i class="fas fa-times-circle" @click="event => deletePhotoFromProject(event, picture)"></i>
                      <span>{{ picture.folder ? picture.folder : '全て' }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button type="button" class="btn btn-sm btn-info px-5" :disabled="!can_upload_photo" @click="uploadPhotoFile">アップロード</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="modal-documents" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="photo-modal-header">
                <img src="/imgs/document.png" />
                <span>DOCUMENT</span>
              </div>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" v-if="documentEdit">
              <div class="text-center">
                <div class="mb-3">{{ documentEdit.project.name }}</div>
                <div class="category-selector">
                  <select class="form-control" v-model="documentEdit.folder">
                    <option value="">全て</option>
                    <option v-for="(value, index) in folders" :key="`folder_${index}`" :value="value">{{ value }}</option>
                  </select>
                </div>
                <file-selector
                  accept-extensions=".doc,.docx,.xls,.xlsx,.csv,.pdf,.ppt"
                  :multiple="false"
                  :max-file-size="30 * 1024 * 1024"
                  @validated="handleDocumentFilesValidated"
                  @changed="changeDocument"
                  input-name="file"
                  >
                  <slot name="top">
                    <div class="document-file-select" v-if="documentEdit.filename">
                      <span>{{ documentEdit.filename }}</span>
                      <i class="fas fa-times-circle" @click="event => removeDocument(event)"></i>
                    </div>
                    <p class="mt-3 mb-3" v-else>
                      <img src="/imgs/icon_upload.svg" alt="" />
                    </p>
                  </slot>
                  <slot name="default">
                    <p class="fs-sm text-center mb-2" style="color: #f00;" v-bind:class="{'hidden': documentEdit.filename}">※ファイルサイズ30MBまで</p>
                    <button type="button" class="btn btn-sm btn-default" v-bind:class="{'hidden': documentEdit.filename}">ファイルを追加する</button>
                  </slot>
                  <slot name="bottom">
                    <p class="mt-3 mb-3" v-bind:class="{'hidden': documentEdit.filename}">または、ファイルをドロップしてアップロード</p>
                  </slot>
                </file-selector>
                <template v-if="documentEdit.project.pictures.length > 0">
                  <hr/>
                  <div class="project-documents">
                    <div v-for="(document, ind) in documentEdit.project.documents" :key="`document_${ind}`" class="document-item">
                      <span class="document-folder mr-3">{{ document.folder ? document.folder : '全て' }}</span>
                      <a :href="document.file_url" target="_blank">{{ document.filename ? document.filename : document.folder }}</a>
                      <i class="fas fa-times-circle" @click="event => deleteDocumentFromProject(event, document)"></i>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button type="button" class="btn btn-sm btn-info px-5" :disabled="!can_upload_document" @click="uploadDocumentFile">アップロード</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(project, index) in projects" :key="`p_modal_${index}`" class="modal fade" :id="`history-modal-${project.id}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">変更履歴</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body history-body">
            <div v-if="project.histories.length == 0" class="text-center color-gray">変更履歴はありません。</div>
            <template v-else>
              <div v-for="(history, ind) in project.histories" :key="`h_${ind}`" class="d-flex align-items-center">
                <span class="mr-3">{{ history.created_at_label }}</span>
                <span>{{ history.user_name }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <vue-easy-lightbox
      :moveDisabled="true"
      :visible="fullImage !== undefined"
      :imgs="fullImages"
      :index="fullImageIndex"
      @hide="fullImage = undefined"
    ></vue-easy-lightbox>
    <loading :active.sync="isLoading"
      :can-cancel="false"
      color="#1D1F32"
      :width="36"
      :height="36"
      :is-full-page="true"></loading>
  </div>
</template>

<script>
  import moment from 'moment';
  import Datepicker from 'vue3-datepicker'
  import { ja } from 'date-fns/locale'

  export default {
    props: ['csrf', 'paramProjects', 'paramSections', 'updateUrl', 'paramTraders', 'paramStaffs', 'paramFolders', 'paramAlbums', 'paramHolidays', 'paramIndustries', 'paramOffices', 'paramSteps', 'userId'],
    components: {
      Datepicker
    },
    data() {
      return {
        projects: undefined,
        sections: [],
        goalEdit: '',
        isGoalEditing: false,
        currentTimeline: undefined,
        noteEdit: {
          staff: '',
          level: 'normal',
          content: ''
        },
        isLoading: false,
        traders: [],
        industries: [],
        commitsEdit: [],
        offices: [],
        staffs: [],
        projectEdit: {},
        ja: ja,
        commitMilestone: undefined,
        albums: [],
        folders: [],
        photoEdit: undefined,
        fullImage: undefined,
        fullImages: [],
        documentEdit: undefined,
        holidays: [],
        isGuest: false,
      }
    },
    created() {
      this.traders = JSON.parse(this.paramTraders)
      this.industries = JSON.parse(this.paramIndustries)
      this.staffs = JSON.parse(this.paramStaffs)
      this.sections = JSON.parse(this.paramSections)
      this.folders = JSON.parse(this.paramFolders)
      this.albums = JSON.parse(this.paramAlbums)
      let temp = JSON.parse(this.paramProjects).data
      this.holidays = JSON.parse(this.paramHolidays)
      this.offices = JSON.parse(this.paramOffices)
      this.isGuest = [18,19,20,21].includes(Number(this.userId))

      temp = temp.map(item => {
        item.isOpen = false
        item.timelines = this.timelines(item)

        return item
      })

      this.projects = temp
    },
    mounted() {
      $('#pagination').removeClass('hidden');
    },
    methods: {
      timelines(project) {
        let days = []
        let day = moment(project.start_at)
        const end = moment(project.completed_at)
        const weekdays = ['日', '月', '火', '水', '木', '金', '土']
        do {
          let weekday = day.format('d')
          const dateStr = day.format('YYYY-MM-DD')
          const is_holiday = this.isHoliday(day.format('YYYY-MM-DD'))
          let commits = project.commits.filter(item => {
            if (item.holidays.includes('sat') && weekday == 6) return false
            if (item.holidays.includes('sun') && weekday == 0) return false
            if (item.holidays.includes('holiday') && is_holiday) return false

            if (item.end_at) {
              return dateStr >= moment(item.start_at).format('YYYY-MM-DD') && dateStr <= moment(item.end_at).format('YYYY-MM-DD');
            }
            return dateStr >= moment(item.start_at).format('YYYY-MM-DD');
          })
          let goals = project.goals.filter(item => moment(item.date).format('YYYY-MM-DD') == dateStr)
          let milestones = project.milestones.filter(item => moment(item.date).format('YYYY-MM-DD') == dateStr)
          let notes = project.notes.filter(item => moment(item.date).format('YYYY-MM-DD') == dateStr)

          days.push({
            day: day.format('YYYY-MM-DD'),
            label: day.format('MM/DD ') + weekdays[weekday],
            customClass: is_holiday ? 'holiday' : (weekday == 6 ? 'sat' : (weekday == 0 ? 'sun' : '')),
            isChecked: project.checked.includes(dateStr),
            commits: commits,
            goal: goals.length > 0 ? goals[0].text : '',
            milestone: milestones.length > 0 ? milestones[0].section : '',
            notes: notes,
            project_id: project.id
          })

          day.add(1, 'days')
        } while (day.format('YYYY-MM-DD') <= end.format('YYYY-MM-DD'))
        return days
      },
      toggleAll(value) {
        this.projects = this.projects.map(item => {
          item.isOpen = value
          return item
        })
      },
      checkDate(project, timeline) {
        timeline.isChecked = !timeline.isChecked
        let checked = project.timelines.filter(item => item.isChecked).map(item => item.day)
        this.update(project.id, 'checked', {
          checked: checked
        })
        this.projects = this.projects.map(project => {
          if (project.id == timeline.project_id) project.checked = checked

          return project
        })
        this.$forceUpdate();
      },
      async update(project_id, action, params) {
        return await axios.post(this.updateUrl, Object.assign({
          _token: this.csrf,
          action: action,
          project_id: project_id
        }, params))
      },
      editGoal(timeline) {
        this.currentTimeline = timeline
        this.goalEdit = timeline.goal
        this.isGoalEditing = true
      },
      addNote(timeline) {
        this.currentTimeline = timeline
        this.noteEdit = {
          staff: '',
          level: 'normal',
          content: ''
        }
      },
      editNote(timeline, note) {
        this.currentTimeline = timeline
        this.noteEdit = {
          staff: note.staff ? note.staff.id : '',
          level: note.level,
          content: note.content,
          id: note.id
        }
      },
      updateGoal() {
        this.update(this.currentTimeline.project_id, 'goal', {
          date: this.currentTimeline.day,
          text: this.goalEdit
        })

        this.projects = this.projects.map(project => {
          if (project.id == this.currentTimeline.project_id) {
            if (project.goals.filter(goal => moment(goal.date).format('YYYY-MM-DD') == this.currentTimeline.day).length > 0) {
              project.goals = project.goals.map(goal => {
                if (moment(goal.date).format('YYYY-MM-DD') == this.currentTimeline.day) goal.text = this.goalEdit

                return goal
              })
            } else {
              project.goals.push({
                date: `${this.currentTimeline.day} 12:00:00`,
                text: this.goalEdit
              })
            }
          }

          return project
        })

        this.currentTimeline.goal = this.goalEdit
        this.currentTimeline = undefined
        this.isGoalEditing = false
        this.$forceUpdate()
      },
      cancelGoalEdit() {
        this.currentTimeline = undefined
        this.isGoalEditing = false
      },
      async deleteNote() {
        this.update(this.currentTimeline.project_id, 'note_delete', {
          id: this.noteEdit.id
        })
        this.currentTimeline.notes = this.currentTimeline.notes.filter(item => item.id != this.noteEdit.id)
      },
      async updateNote() {
        const response = await this.update(this.currentTimeline.project_id, this.noteEdit.id ? 'note_update' : 'note_add', {
          date: this.currentTimeline.day,
          content: this.noteEdit.content,
          level: this.noteEdit.level,
          id: this.noteEdit.id,
          staff_id: this.noteEdit.staff
        })

        if (this.noteEdit.id) {
          this.currentTimeline.notes = this.currentTimeline.notes.map(note => {
            if (note.id == this.noteEdit.id) {
              note.staff = this.noteEdit.staff == '' ? undefined : this.staffs.filter(item => item.id == this.noteEdit.staff)[0];
              note.level = this.noteEdit.level
              note.content = this.noteEdit.content
            }
            return note;
          })
        } else {
          this.currentTimeline.notes.push({
            staff: this.noteEdit.staff == '' ? undefined : this.staffs.filter(item => item.id == this.noteEdit.staff)[0],
            level: this.noteEdit.level,
            content: this.noteEdit.content,
            date: this.currentTimeline.day,
            project_id: this.currentTimeline.project_id,
            id: response.data.res.id
          })
        }

        this.projects = this.projects.map(item => {
          if (item.id == this.currentTimeline.project_id) {
            item.timelines = item.timelines.map(timeline => {
              if (timeline.day == this.currentTimeline.day) {
                return this.currentTimeline
              }

              return timeline
            })
          }

          return item
        })

        this.currentTimeline = undefined
      },
      updateSection(project) {
        this.update(project.id, 'update_section', {
          section: project.section
        })
      },
      editCommit(timeline) {
        this.currentTimeline = timeline
        this.commitMilestone = timeline.milestone
        this.commitsEdit = timeline.commits.map(item => {
          if (item.trader) {
            item.trader_option = {
              code: item.trader.id,
              label: item.trader.name
            }
          } else {
            item.trader_option = {
              code: '',
              label: '業者'
            }
          }
          if (item.industry) {
            item.industry_option = {
              code: item.industry.id,
              label: item.industry.name
            }
          } else {
            item.industry_option = {
              code: '',
              label: '業種'
            }
          }
          if (item.step) {
            item.step_option = {
              code: item.step,
              label: item.step
            }
          } else {
            item.step_option = {
              code: '',
              label: ''
            }
          }
          if (item.start_at) item.start_at = moment(item.start_at).format('YYYY-MM-DD')
          if (item.end_at) item.end_at = moment(item.end_at).format('YYYY-MM-DD')
          return item
        })
        if (this.commitsEdit.length == 0) this.addCommit()
      },
      addCommit() {
        this.commitsEdit.push({
          step_option: {
            code: '',
            label: ''
          },
          trader_option: {
            code: '',
            label: '業者'
          },
          industry_option: {
            code: '',
            label: '業種'
          },
          content: '',
          id: -1,
          start_at: this.currentTimeline.day,
          end_at: this.currentTimeline.day,
          holidays: ['sun']
        })
      },
      removeCommit(event, ind) {
        event.preventDefault();
        event.stopPropagation()
        this.commitsEdit = this.commitsEdit.filter((item, index) => index != ind)
      },
      async updateCommits() {
        this.isLoading = true
        const checkResponse = await axios.post('/projects/commits/check', {
          _token: this.csrf,
          project_id: this.currentTimeline.project_id,
          date: this.currentTimeline.day,
          commits: this.commitsEdit.filter(item => item.step_option && item.step_option.label != '' && item.trader_option && item.trader_option.code != '').map(item => {
            return {
              trader_id: item.trader_option ? item.trader_option.code : ''
            }
          }),
        })

        if (checkResponse.data.res != '') {
          this.isLoading = false
          centerToastr.fire({
            html: `${checkResponse.data.res}様は同日に既に他の物件の工事予定が登録されています。<br/>このまま予定を登録してよろしいでしょうか？`,
            icon: 'info',
            showCloseButton: true,
            showDenyButton: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "登録する",
            cancelButtonText: "キャンセル",
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#BDBDBD",
            allowOutsideClick: false,
            allowEscapeKey: false,
            timer: undefined
          }).then((result) => {
            if (result.isConfirmed) {
              this.uploadCommits();
            }
          })
        } else {
          this.uploadCommits();
        }

      },
      async uploadCommits() {
        this.isLoading = true
        const response = await this.update(this.currentTimeline.project_id, 'update_commits', {
          commits: this.commitsEdit.filter(item => item.step_option && item.step_option.label != '').filter(item => item.start_at).map(item => {
            return {
              id: item.id,
              step: item.step_option ? item.step_option.label : '',
              content: item.content,
              trader_id: item.trader_option ? item.trader_option.code : '',
              industry_id: item.industry_option ? item.industry_option.code : '',
              start_at: item.start_at,
              end_at: (!item.end_at || item.end_at < item.start_at) ? item.start_at : item.end_at,
              holidays: item.holidays.filter(item => item != '').join(',')
            }
          }),
          date: this.currentTimeline.day,
          milestone: this.commitMilestone
        })

        try {
          this.currentTimeline.milestone = this.commitMilestone
          this.currentTimeline.commits = response.data.res
        } catch (error) {

        }
        this.isLoading = false
        $('#btnUpdateCommitClose').click();
        this.projects = this.projects.map(item => {
          if (item.id == this.currentTimeline.project_id) {
            item.milestone = this.commitMilestone
            item.commits = this.currentTimeline.commits
            if (item.milestones.filter(t => moment(t.date).format('YYYY-MM-DD') == this.currentTimeline.day).length > 0) {
              item.milestones = item.milestones.map(t => {
                if (moment(t.date).format('YYYY-MM-DD') == this.currentTimeline.day) t.section = this.commitMilestone

                return t
              })
            } else {
              item.milestones.push({
                date: this.currentTimeline.day,
                section: this.commitMilestone
              })
            }
            item.timelines = this.timelines(item)
          }

          return item
        })
        this.$forceUpdate();
      },
      editProject(project) {
        let temp = Object.assign({}, project)
        temp.start_at = new Date(temp.start_at)
        temp.completed_at = new Date(temp.completed_at)
        temp.manager = temp.manager && temp.manager != '' ? { code: temp.manager, label: temp.manager } : { code: '', label: '営業' }
        temp.supervisor = temp.supervisor && temp.supervisor != '' ? { code: temp.supervisor, label: temp.supervisor } : { code: '', label: '-' }
        temp.construction_manager = temp.construction_manager && temp.construction_manager != '' ? { code: temp.construction_manager, label: temp.construction_manager } : { code: '', label: '-' }
        this.projectEdit = Object.assign({}, temp)
      },
      updateProject() {
        let temp = Object.assign({}, this.projectEdit)
        temp.start_at = moment(temp.start_at).format('YYYY-MM-DD')
        temp.completed_at = moment(temp.completed_at).format('YYYY-MM-DD')
        temp.start_at_label = moment(temp.start_at).format('YYYY-MM-DD')
        temp.completed_at_label = moment(temp.completed_at).format('YYYY-MM-DD')
        temp.manager = temp.manager && temp.manager.code != '' ? temp.manager.code : ''
        temp.supervisor = temp.supervisor && temp.supervisor.code != '' ? temp.supervisor.code : ''
        temp.construction_manager = temp.construction_manager && temp.construction_manager.code != '' ? temp.construction_manager.code : ''
        this.projects = this.projects.map(project => {
          if (project.id == temp.id) {
            temp.timelines = this.timelines(temp)
            return temp
          }
          return project
        })

        this.update(temp.id, 'update_project', {
          code: temp.code,
          name: temp.name,
          start_at: temp.start_at,
          completed_at: temp.completed_at,
          room_number: temp.room_number,
          address: temp.address,
          saturday_work: temp.saturday_work,
          saturday_noise: temp.saturday_noise,
          manager: temp.manager,
          supervisor: temp.supervisor,
          construction_manager: temp.construction_manager,
          key_place: temp.key_place,
          client_name: temp.client_name,
          integration_link: temp.integration_link,
          note: temp.note
        })
      },
      async copyProject() {
        let temp = Object.assign({}, this.projectEdit)
        temp.start_at = moment(temp.start_at).format('YYYY-MM-DD')
        temp.completed_at = moment(temp.completed_at).format('YYYY-MM-DD')
        temp.start_at_label = moment(temp.start_at).format('YYYY-MM-DD')
        temp.completed_at_label = moment(temp.completed_at).format('YYYY-MM-DD')
        temp.manager = temp.manager && temp.manager.code != '' ? temp.manager.code : ''
        temp.supervisor = temp.supervisor && temp.supervisor.code != '' ? temp.supervisor.code : ''
        temp.construction_manager = temp.construction_manager && temp.construction_manager.code != '' ? temp.construction_manager.code : ''
        this.isLoading = true

        let res = await this.update(temp.id, 'copy_project', {
          code: temp.code,
          name: temp.name,
          start_at: temp.start_at,
          completed_at: temp.completed_at,
          room_number: temp.room_number,
          address: temp.address,
          saturday_work: temp.saturday_work,
          saturday_noise: temp.saturday_noise,
          manager: temp.manager,
          supervisor: temp.supervisor,
          construction_manager: temp.construction_manager,
          key_place: temp.key_place,
          client_name: temp.client_name,
          integration_link: temp.integration_link,
          note: temp.note
        })

        let project = res.data.res
        project.timelines = this.timelines(project)
        this.projects = [...[project], ...this.projects]

        this.isLoading = false
      },
      deleteProject() {
        let self = this;
        centerToastr.fire({
          html: "この案件を削除しますか？",
          icon: 'info',
          showCloseButton: true,
          showDenyButton: false,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "削除する",
          cancelButtonText: "閉じる",
          confirmButtonColor: "#dc3545",
          cancelButtonColor: "#BDBDBD",
          allowOutsideClick: false,
          allowEscapeKey: false,
          timer: undefined
        }).then((result) => {
          if (result.isConfirmed) {
            self.update(self.projectEdit.id, 'delete', {})
            self.projects = self.projects.filter(project => project.id != self.projectEdit.id)
            $('#cancelProjectEditModal').click();
          }
        })
      },
      refreshFullImages() {
        this.fullImage = undefined
        this.fullImages = []
        if (this.photoEdit && this.photoEdit.project) {
          this.photoEdit.project.pictures.map(picture => {
            this.fullImages.push(picture.file_url)
          })
        }
      },
      editPhoto(project) {
        let temp = Object.assign({}, project)
        this.photoEdit = {
          project: temp,
          album: '',
          image: undefined
        }
        this.refreshFullImages()
      },
      handleFilesValidated(result, files) {
        if (result === "FILE_SIZE_ERROR") {
          centerToastr.fire({
            icon: 'warning',
            text: "15mbまで選択してください。"
          })
        }
        if (result === "EXTENSION_ERROR") {
          centerToastr.fire({
            icon: 'warning',
            text: ".jpg,.jpeg,.pngファイルを選択してください。"
          })
        }
      },
      changeImage(files) {
        if (!files.length) return;
        this.createImage(files[0]);
      },
      createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.photoEdit.image = e.target.result;
          this.$forceUpdate();
        };
        this.photoEdit.file = file
        reader.readAsDataURL(file);
      },
      removeImage(event) {
        event.preventDefault();
        event.stopPropagation();
        this.photoEdit.image = undefined
        this.photoEdit.file = undefined
      },
      uploadPhotoFile() {
        if (!this.photoEdit.image) return;
        if (this.isLoading) return;

        this.isLoading = true

        let formData = new FormData();
        formData.append('type', 'picture')
        formData.append('folder', this.photoEdit.album)
        formData.append('file', this.photoEdit.file)
        formData.append('project_id', this.photoEdit.project.id)
        axios.post( '/projects/upload/photo',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(response => {
          this.photoEdit.project = response.data.project
          this.photoEdit.project.timelines = this.timelines(this.photoEdit.project)
          this.projects = this.projects.map(project => {
            if (project.id == this.photoEdit.project.id) return this.photoEdit.project

            return project
          })

          this.photoEdit.album = ''
          this.photoEdit.image = undefined
          this.photoEdit.file = undefined
          this.refreshFullImages()
        })
        .catch(error => {
          centerToastr.fire({
            icon: 'warning',
            text: "アップロードできませんでした。"
          })
        }).finally(() => {
          this.isLoading = false
        })
      },
      deletePhotoFromProject(event, picture) {
        event.preventDefault();
        event.stopPropagation();
        if (this.isLoading) return;

        this.isLoading = true;
        axios.post( '/projects/delete/photo',
          {
            file_id: picture.id
          }
        ).then(response => {
          this.photoEdit.project = response.data.project
          this.photoEdit.project.timelines = this.timelines(this.photoEdit.project)
          this.projects = this.projects.map(project => {
            if (project.id == this.photoEdit.project.id) return this.photoEdit.project

            return project
          })
          this.refreshFullImages()
        })
        .catch(error => {
          centerToastr.fire({
            icon: 'warning',
            text: "削除できませんでした。"
          })
        }).finally(() => {
          this.isLoading = false
        })
      },
      editDocument(project) {
        let temp = Object.assign({}, project)
        this.documentEdit = {
          project: temp,
          folder: '',
          file: undefined,
          filename: undefined
        }
      },
      handleDocumentFilesValidated(result, files) {
        if (result === "FILE_SIZE_ERROR") {
          centerToastr.fire({
            icon: 'warning',
            text: "30mbまで選択してください。"
          })
        }
        if (result === "EXTENSION_ERROR") {
          centerToastr.fire({
            icon: 'warning',
            text: ".doc,.docx,.xls,.xlsx,.csv,.pdf,.pptファイルを選択してください。"
          })
        }
      },
      changeDocument(files) {
        if (!files.length) return;
        this.documentEdit.file = files[0]
        this.documentEdit.filename = files[0].name;
      },
      removeDocument(event) {
        event.preventDefault();
        event.stopPropagation();
        this.documentEdit.filename = undefined
        this.documentEdit.file = undefined
      },
      uploadDocumentFile() {
        if (!this.documentEdit.filename) return;
        if (this.isLoading) return;

        this.isLoading = true

        let formData = new FormData();
        formData.append('type', 'document')
        formData.append('folder', this.documentEdit.folder)
        formData.append('file', this.documentEdit.file)
        formData.append('project_id', this.documentEdit.project.id)
        axios.post( '/projects/upload/photo',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(response => {
          this.documentEdit.project = response.data.project
          this.documentEdit.project.timelines = this.timelines(this.documentEdit.project)
          this.projects = this.projects.map(project => {
            if (project.id == this.documentEdit.project.id) return this.documentEdit.project

            return project
          })

          this.documentEdit.folder = ''
          this.documentEdit.filename = undefined
          this.documentEdit.file = undefined
        })
        .catch(error => {
          centerToastr.fire({
            icon: 'warning',
            text: "アップロードできませんでした。"
          })
        }).finally(() => {
          this.isLoading = false
        })
      },
      deleteDocumentFromProject(event, file) {
        event.preventDefault();
        event.stopPropagation();
        if (this.isLoading) return;

        this.isLoading = true;
        axios.post( '/projects/delete/photo',
          {
            file_id: file.id
          }
        ).then(response => {
          this.documentEdit.project = response.data.project
          this.documentEdit.project.timelines = this.timelines(this.documentEdit.project)
          this.projects = this.projects.map(project => {
            if (project.id == this.documentEdit.project.id) return this.documentEdit.project

            return project
          })
        })
        .catch(error => {
          centerToastr.fire({
            icon: 'warning',
            text: "削除できませんでした。"
          })
        }).finally(() => {
          this.isLoading = false
        })
      },
      isHoliday(value) {
        return this.holidays.filter(item => value >= item.start_label && value <= item.end_label).length > 0;
      },
      scrollToToday() {
        let self = this
        const today = moment().format('YYYY-MM-DD')
        setTimeout(function() {
          self.projects = self.projects.map(project => {
            if (project.isOpen && !project.isFirstOpened) {
              if ($(`#td_${project.id}_${today}`).length > 0) {
                $(`#timeline_${project.id}`).animate({scrollLeft: $(`#td_${project.id}_${today}`).offset().left - 40}, 10);
              }
              project.isFirstOpened = true
            }
            return project
          })
        }, 300)
      },
      toggleCommitHoliday(index, value) {
        this.commitsEdit = this.commitsEdit.map((commit, ind) => {
          if (ind == index) {
            if (commit.holidays.includes(value))
              commit.holidays = commit.holidays.filter(item => item != value)
            else
              commit.holidays.push(value)
          }

          return commit
        })
      }
    },
    computed: {
      trader_options() {
        if (this.traders) return this.traders.map(item => {
          return {
            code: item.id,
            label: item.name
          }
        })

        return []
      },
      industry_options() {
        if (this.industries) return this.industries.map(item => {
          return {
            code: item.id,
            label: item.name
          }
        })

        return []
      },
      steps() {
        const temp = JSON.parse(this.paramSteps)

        return temp.map(item => {
          return {
            code: item,
            label: item
          }
        })
      },
      trader_names() {
        if (this.traders) return this.traders.map(item => {
          return {
            code: item.name,
            label: item.name
          }
        })

        return []
      },
      staff_options() {
        if (this.staffs) return this.staffs.map(item => {
          return {
            code: item.name,
            label: item.name
          }
        })

        return []
      },
      staff2_options() {
        const values = []
        values.push({
          code: '全員',
          label: '全員'
        })

        if (this.staffs) {
          this.staffs.map(item => {
            values.push({
              code: item.name,
              label: item.name
            })
          })
        }

        return values
      },
      office_options() {
        if (this.offices) return this.offices.map(item => {
          return {
            code: item.name,
            label: item.name
          }
        })

        return []
      },
      can_save() {
        if (this.projectEdit == undefined) return false;
        return this.projectEdit.code != '' && this.projectEdit.name != '' && this.projectEdit.start_at != undefined && this.projectEdit.completed_at != undefined
      },
      album_image() {
        if (this.photoEdit && this.photoEdit.image) return this.photoEdit.image;

        return "/imgs/no-image.jpg";
      },
      can_upload_photo() {
        if (this.photoEdit && this.photoEdit.image) return true;

        return false;
      },
      fullImageIndex() {
        let index = 0;
        this.fullImages.map((item, ind) => {
          if (item === this.fullImage) index = ind;
        });

        return index;
      },
      can_upload_document() {
        if (this.documentEdit && this.documentEdit.filename) return true;

        return false;
      },
    }
  }
</script>
<style lang="scss" scoped>
.btn-open {
  background-color: #136F87;
  color: #fff;
  padding: 0 15px;
  line-height: 24px;
  height: 24px;
  text-align: center;
}

.project-info {
  .label {
    border: 1px solid black;
    line-height: 16px;
    display: inline-block;
    text-align: center;
    font-style: inherit;
    margin-right: 0.5em;
    border-radius: 4px;
    font-size: 11px;
    vertical-align: top;
    padding: 2px 12px;
  }
}
.table > :not(:first-child) {
  border-top: 1px solid #ccc;
}
.disabled {
  opacity: .5;
}
.btn-history {
  width: 34px;
  height: 34px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  border-radius: 17px;
  border: 2px solid #f00;
  color: #f00;
  font-weight: bold;
  text-decoration: none;
  padding-top: 3px;
}

.history-body {
  max-height: 50vh;
  overflow-y: auto;
  font-size: 14px;
}
.holiday {
  background: #aa00aa;
  color: #fff;
}
</style>
