import './bootstrap';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueClipboard from 'vue3-clipboard'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import FileSelector from 'vue-file-selector';
import Lightbox from 'vue-easy-lightbox'

import { createApp } from 'vue';

window.topToastr = Swal.mixin({
  showConfirmButton: false,
  timer: 3000,
  position: 'top-end',
  toast: true,
  customClass: {
    popup: 'flex-row',
    container: 'top-alert'
  }
});

window.centerToastr = Swal.mixin({
  showConfirmButton: false,
  timer: 3000,
  position: 'center',
  toast: false
});

const app = createApp();

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.use(FileSelector);
app.use(Lightbox);

import Flash from './common/Flash.vue';
import CommonLoading from './common/Loading.vue';
import CustomRadio from './common/CustomRadio.vue';
import CustomCheck from './common/CustomCheck.vue';
import Projects from './common/Projects.vue';
import ProjectCreate from './common/ProjectCreate.vue';
import CustomCalendar from './common/CustomCalendar.vue';
import CustomSelect from './common/CustomSelect.vue';

app.component("v-select", vSelect);
app.component('loading', Loading);
app.component('flash', Flash);
app.component('common-loading', CommonLoading);
app.component('custom-radio', CustomRadio);
app.component('custom-check', CustomCheck);
app.component('projects', Projects);
app.component('project-create', ProjectCreate);
app.component('custom-calendar', CustomCalendar);
app.component('custom-select', CustomSelect);

app.mount('#page_wrapper');
