<template>
  <div>
    <span style="display: none;" data-bs-toggle="modal" data-bs-target="#modal-project-create" @click="createProject" id="btnCreateProject"></span>
    <div class="modal fade" id="modal-project-create" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">案件を新規作成</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-2">
              <label class="col-sm-12 mb-3 text-bold">CSVをアップロード</label>
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">物件コード</label>
              <div class="col-sm-4">
                <input type="text" name="csv_code" class="form-control" v-model="csvUpload.code" />
              </div>
              <div class="col-sm-4">
                <input type="file" name="csv_file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="fileUploaded = true" />
              </div>
              <div class="col-sm-12 mt-3 mb-3">
                <button type="button" class="btn btn-sm btn-info w-100 text-center" :disabled="csvUpload.code == '' || fileUploaded == false" @click="uploadFile">アップロード</button>
              </div>
              <hr />
              <label class="col-sm-12 mt-4 mb-3 text-bold">ブラウザから登録</label>
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">営業</label>
              <div class="col-sm-4">
                <v-select :options="staff_options" placeholder="営業" v-model="projectEdit.manager">
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      営業はございません。
                    </template>
                    <em v-else style="opacity: 0.5">検索中...</em>
                  </template>
                </v-select>
              </div>
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">物件コード<span class="ml-2 color-red">*</span></label>
              <div class="col-sm-4">
                <input type="text" class="form-control" v-model="projectEdit.code" v-bind:class="{'is-invalid': projectEdit.code == ''}" />
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">お取引先</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" v-model="projectEdit.client_name" />
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">現場名<span class="ml-2 color-red">*</span></label>
              <div class="col-sm-10 d-flex align-items-center justify-content-center">
                <input type="text" class="form-control mr-3" v-model="projectEdit.name" v-bind:class="{'is-invalid': projectEdit.name == ''}" />
                <input type="text" class="form-control mr-3" v-model="projectEdit.room_number" />
                <span style="width: 100px;">号室</span>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">現場住所</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" v-model="projectEdit.address" />
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">着工<span class="ml-2 color-red">*</span></label>
              <div class="col-sm-4">
                <datepicker v-model="projectEdit.start_at" :locale="ja" class="form-control" v-bind:class="{'is-invalid': projectEdit.start_at == undefined}" :clearable="true" />
              </div>
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">完工</label>
              <div class="col-sm-4">
                <datepicker v-model="projectEdit.completed_at" :locale="ja" class="form-control" :clearable="true" />
              </div>
              <div class="col-12 d-flex justify-content-end"><small>完工日を空欄で保存するとデフォルト工程で保存されます</small></div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">土曜作業</label>
              <div class="col-sm-4">
                <div class="inline-block">
                  <div class="custom-radio">
                    <div class="radio-item" v-bind:class="{'checked': projectEdit.saturday_work == 1}" @click="projectEdit.saturday_work = 1">
                      可能
                    </div>
                    <div class="radio-item" v-bind:class="{'checked': projectEdit.saturday_work == 0}" @click="projectEdit.saturday_work = 0">
                      不可
                    </div>
                  </div>
                </div>
              </div>
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">土曜音出し</label>
              <div class="col-sm-4">
                <div class="inline-block">
                  <div class="custom-radio">
                    <div class="radio-item" v-bind:class="{'checked': projectEdit.saturday_noise == 1}" @click="projectEdit.saturday_noise = 1">
                      可能
                    </div>
                    <div class="radio-item" v-bind:class="{'checked': projectEdit.saturday_noise == 0}" @click="projectEdit.saturday_noise = 0">
                      禁止
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">統括管理部門</label>
              <div class="col-sm-4">
                <v-select :options="office_options" placeholder="-" v-model="projectEdit.supervisor">
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      統括管理部門はございません。
                    </template>
                    <em v-else style="opacity: 0.5">検索中...</em>
                  </template>
                </v-select>
              </div>
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">工事担当</label>
              <div class="col-sm-4">
                <v-select :options="staff_options" placeholder="-" v-model="projectEdit.construction_manager">
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      工事担当はございません。
                    </template>
                    <em v-else style="opacity: 0.5">検索中...</em>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">鍵の場所</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" v-model="projectEdit.key_place" />
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">備考</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" v-model="projectEdit.note" />
              </div>
            </div>
            <div class="row mb-2">
              <label class="col-sm-2 color-gray font-12 mb-1 d-flex align-items-center">積算表リンク</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" v-model="projectEdit.integration_link" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary mr-3 px-4" data-bs-dismiss="modal" id="cancelProjectEditModal">キャンセル</button>
            <button type="submit" class="btn btn-sm btn-info mr-3 px-4" :disabled="!can_save">保存</button>
          </div>
        </div>
      </div>
    </div>
    <input type="hidden" name="manager" :value="manager_value" />
    <input type="hidden" name="section" value="pending" />
    <input type="hidden" name="code" :value="projectEdit.code" />
    <input type="hidden" name="name" :value="projectEdit.name" />
    <input type="hidden" name="start_at" :value="start_at_value" />
    <input type="hidden" name="completed_at" :value="completed_at_value" />
    <input type="hidden" name="room_number" :value="projectEdit.room_number" />
    <input type="hidden" name="address" :value="projectEdit.address" />
    <input type="hidden" name="saturday_work" :value="projectEdit.saturday_work" />
    <input type="hidden" name="saturday_noise" :value="projectEdit.saturday_noise" />
    <input type="hidden" name="supervisor" :value="supervisor_value" />
    <input type="hidden" name="construction_manager" :value="construction_manager_value" />
    <input type="hidden" name="key_place" :value="projectEdit.key_place" />
    <input type="hidden" name="client_name" :value="projectEdit.client_name" />
    <input type="hidden" name="integration_link" :value="projectEdit.integration_link" />
    <input type="hidden" name="note" :value="projectEdit.note" />
    <loading :active.sync="isLoading"
      :can-cancel="false"
      color="#1D1F32"
      :width="36"
      :height="36"
      :is-full-page="true"></loading>
  </div>
</template>
 
<script>
  import moment from 'moment';
  import Datepicker from 'vue3-datepicker'
  import { ja } from 'date-fns/locale'

  export default {
    props: ['csrf', 'updateUrl', 'paramTraders', 'paramStaffs', 'paramOffices'],
    components: {
      Datepicker
    },
    data() {
      return {
        projects: undefined,
        traders: [],
        staffs: [],
        offices: [],
        projectEdit: {
          code: '',
          name: '',
          start_at: undefined,
          completed_at: undefined,
          room_number: '',
          address: '',
          saturday_work: 1,
          saturday_noise: 1,
          manager: {
            code: '',
            label: '営業'
          },
          supervisor: {
            code: '',
            label: '-'
          },
          construction_manager: {
            code: '',
            label: '-'
          },
          key_place: '',
          client_name: '',
          integration_link: '',
          note: ''
        },
        ja: ja,
        csvUpload: {
          code: ''
        },
        fileUploaded: false,
        isLoading: false
      }
    },
    created() {
      this.traders = JSON.parse(this.paramTraders)
      this.staffs = JSON.parse(this.paramStaffs)
      this.offices = JSON.parse(this.paramOffices)
    },
    methods: {
      async update(project_id, action, params) {
        return await axios.post(this.updateUrl, Object.assign({
          _token: this.csrf,
          action: action,
          project_id: project_id
        }, params))
      },
      createProject() {
        this.projectEdit = {
          code: '',
          name: '',
          start_at: undefined,
          completed_at: undefined,
          room_number: '',
          address: '',
          saturday_work: 1,
          saturday_noise: 1,
          manager: {
            code: '',
            label: '営業'
          },
          supervisor: {
            code: '',
            label: '-'
          },
          construction_manager: {
            code: '',
            label: '-'
          },
          key_place: '',
          client_name: '',
          integration_link: '',
          note: ''
        }
      },
      uploadFile() {
        this.isLoading = true
        $('#createForm').attr('action', '/projects/upload');
        $('#createForm').submit();
      }
    },
    computed: {
      trader_options() {
        if (this.traders) return this.traders.map(item => {
          return {
            code: item.id,
            label: item.name
          }
        })

        return []
      },
      trader_names() {
        if (this.traders) return this.traders.map(item => {
          return {
            code: item.name,
            label: item.name
          }
        })

        return []
      },
      staff_options() {
        if (this.staffs) return this.staffs.map(item => {
          return {
            code: item.name,
            label: item.name
          }
        })

        return []
      },
      office_options() {
        if (this.offices) return this.offices.map(item => {
          return {
            code: item.name,
            label: item.name
          }
        })

        return []
      },
      can_save() {
        if (this.projectEdit == undefined) return false;
        return this.projectEdit.code != '' && this.projectEdit.name != '' && this.projectEdit.start_at != undefined
      },
      start_at_value() {
        if (this.projectEdit.start_at) return moment(this.projectEdit.start_at).format('YYYY-MM-DD')
        return ''
      },
      completed_at_value() {
        if (this.projectEdit.completed_at) return moment(this.projectEdit.completed_at).format('YYYY-MM-DD')
        return ''
      },
      manager_value() {
        if (this.projectEdit.manager && this.projectEdit.manager.code != '') return this.projectEdit.manager.code
        return ''
      },
      supervisor_value() {
        if (this.projectEdit.supervisor && this.projectEdit.supervisor.code != '') return this.projectEdit.supervisor.code
        return ''
      },
      construction_manager_value() {
        if (this.projectEdit.construction_manager && this.projectEdit.construction_manager.code != '') return this.projectEdit.construction_manager.code
        return ''
      }
    }
  }
</script>
<style lang="scss" scoped>
.btn-open {
  background-color: #136F87;
  color: #fff;
  padding: 0 15px;
  line-height: 24px;
  height: 24px;
  text-align: center;
}

.project-info {
  .label {
    border: 1px solid black;
    line-height: 16px;
    display: inline-block;
    text-align: center;
    font-style: inherit;
    margin-right: 0.5em;
    border-radius: 4px;
    font-size: 11px;
    vertical-align: top;
    padding: 2px 12px;
  }
}
.table > :not(:first-child) {
  border-top: 1px solid #ccc;
}
.disabled {
  opacity: .5;
}
</style>