<template>
  <div>
    <v-select :options="options" :placeholder="label" v-model="value">
      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching">
          {{ label }}はありません。
        </template>
        <em v-else style="opacity: 0.5">検索中...</em>
      </template>
    </v-select>
    <input v-if="value" type="hidden" :name="name" :value="value.code" />
  </div>
</template>
 
<script>
  export default {
    props: ['name', 'oldValue', 'label', 'paramOptions'],
    data() {
      return {
        value: undefined,
        options: [],
        isLoaded: false
      }
    },
    created() {
      this.options = JSON.parse(this.paramOptions)
      if (this.oldValue) {
        this.value = this.options.filter(item => item.code == this.oldValue)[0]
      } else {
        this.isLoaded = true
      }
    },
    watch: {
      value(next, prev) {
        if (this.isLoaded) {
          setTimeout(function() {
            $('#searchForm').submit();
          }, 200)
        } else {
          this.isLoaded = true
        }
      }
    }
  }
</script>
